import { AuthConfig } from '../model/configModel';
import { RefreshResponse } from '../model/authModel';
import * as HttpService from './httpService';

export interface IAuthService {
  buildAuthorizeUrl?: () => string;
  refreshTokens: (idToken: string) => Promise<RefreshResponse>;
}

export default class AuthService implements IAuthService {
  constructor(private readonly config: AuthConfig) {}

  public async refreshTokens(idToken: string): Promise<RefreshResponse> {
    const { refreshUrl } = this.config;

    const encodedToken = encodeURIComponent(idToken);

    const request: HttpService.HttpRequest<void> = {
      url: `${refreshUrl}?id_token=${encodedToken}`,
      acceptHeader: 'application/json',
      contentTypeHeader: 'application/json',
      withCredentials: false,
    };

    const response = await HttpService.post<void, RefreshResponse>(request);

    return response;
  }
}
