/* istanbul ignore file */
import React, { FC } from 'react';
import { Card } from '@material-ui/core';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AppHeader from '../AppHeader/AppHeader';
import FilePickerPage from '../FilePickerPage/FilePickerPage';
import * as Routes from '../../services/routes';
import { useLocalizationState } from '../../hooks/selectors';
import AuthFinalizePage from '../AuthFinalizePage/AuthFinalizePage';

const PageContainer: FC = () => {
  // Adding this unused variable so that when the localized strings are fetched from the server the app re-renders.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { language } = useLocalizationState();

  return (
    <Card>
      <AppHeader />
      <BrowserRouter>
        <Switch>
          <Route path={Routes.authFinalize} component={AuthFinalizePage} />
          <Route path={Routes.index} component={FilePickerPage} />
        </Switch>
      </BrowserRouter>
    </Card>
  );
};

export default PageContainer;
