import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent, CardActions } from '@material-ui/core';
import SelectFileButton from '../Buttons/SelectFileButton/SelectFileButton';
import Form from '../Form/Form';
import { ConnectedResultsContainer } from '../ResultsContainer/ResultsContainer';
import RedirectIfUnauthenticated from '../RedirectIfUnauthenticated/RedirectIfUnauthenticated';

const useStyles = makeStyles({
  actions: {
    marginTop: '1.2em',
  },
});

const FilePickerPage: FC = () => {
  const classes = useStyles();

  return (
    <>
      <RedirectIfUnauthenticated />
      <CardContent>
        <Form id="select-document-form" />
      </CardContent>
      <ConnectedResultsContainer />
      <CardActions className={classes.actions}>
        <SelectFileButton />
      </CardActions>
    </>
  );
};

export default FilePickerPage;
