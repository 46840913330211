/* istanbul ignore file */

import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';

import SearchInput from './SearchInput/SearchInput';

interface FormContainerProps {
  id: string;
}

const FormContainer: FC<FormContainerProps> = ({ id }) => {
  return (
    <FormControl fullWidth hiddenLabel id={id}>
      <SearchInput />
    </FormControl>
  );
};

FormContainer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default FormContainer;
