/* istanbul ignore file */

import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { ReactComponent as DocIcon } from '../../assets/document-icon.svg';

export default function DocumentIcon(props: SvgIconProps): ReactElement {
  const { titleAccess, color } = props;
  return (
    <SvgIcon
      titleAccess={titleAccess}
      color={color}
      component={DocIcon}
      viewBox="0 0 50 50"
    />
  );
}
