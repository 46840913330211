/* istanbul ignore file */

import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { ReactComponent as Search } from '../../assets/search-icon.svg';

export default function SearchIcon(props: SvgIconProps): ReactElement {
  const { titleAccess, color } = props;
  return <SvgIcon titleAccess={titleAccess} color={color} component={Search} />;
}
