import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import LocalizationService from '../../../services/localizationService';
import * as Keys from '../../../services/localizationKeys';
import { submitSelection } from '../../../services/windowMessageService';
import { useSelectionState } from '../../../hooks/selectors';

const useStyles = makeStyles({
  button: {
    marginLeft: 'auto',
    marginRight: '1em',
    textTransform: 'capitalize',
  },
});

const SelectFileButton: FC = () => {
  const classes = useStyles();
  const { selection } = useSelectionState();
  return (
    <Button
      color="primary"
      className={classes.button}
      form="select-document-form"
      type="submit"
      variant="contained"
      onClick={(): void => submitSelection(selection)}
      disabled={selection.selectedFiles.length === 0}
      data-automation-id="file-picker-select-btn"
    >
      {LocalizationService.localize(Keys.SelectAction1, {})}
    </Button>
  );
};

export default SelectFileButton;
