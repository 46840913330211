import React, { FC } from 'react';
import { Container, Typography } from '@material-ui/core';
import LocalizationService from '../../../services/localizationService';
import * as Keys from '../../../services/localizationKeys';
import styleHook from '../styles';
import EmptyStateImg from '../../Assets/EmptyStateImg';

const ErrorView: FC = (): JSX.Element => {
  const styles = styleHook();

  const messageTitle = LocalizationService.localize(
    Keys.SearchErrorMessageTitle,
    {},
  );
  const messageSubTitle = LocalizationService.localize(
    Keys.SearchErrorMessageSubTitle,
    {},
  );

  return (
    <Container className={styles.container}>
      <EmptyStateImg role="presentation" />
      <Typography className={styles.textTitle} variant="h5">
        {messageTitle}
      </Typography>
      <Typography className={styles.text} variant="subtitle1">
        {messageSubTitle}
      </Typography>
    </Container>
  );
};

export default ErrorView;
