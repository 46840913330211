/* istanbul ignore file */

import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ReactComponent as EmptyState } from '../../assets/empty-state.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      height: '10rem',
      marginBottom: 15,
      width: 'auto',
      [theme.breakpoints.down('sm')]: {
        marginTop: 5,
      },
    },
  }),
);

export default function EmptyStateImg(props: SvgIconProps): ReactElement {
  const classes = useStyles();
  const { titleAccess, color } = props;

  return (
    <SvgIcon
      className={classes.img}
      color={color}
      component={EmptyState}
      titleAccess={titleAccess}
      viewBox="0 0 272 255"
    />
  );
}
