/*
 * This module contains everything for the 'selection' section of the Redux store.
 * (State, actions, action factory methods, reducer)
 */

import {
  SelectionResponse,
  getEmptySelection,
} from '../model/clientResponseModel';

export type SelectionState = {
  selection: SelectionResponse;
};

export const defaultSelectionState: SelectionState = {
  selection: getEmptySelection(),
};

export enum SelectionActionTypes {
  UpdateSelection = 'Selection_Update',
}

export type UpdateSelectionAction = {
  type: typeof SelectionActionTypes.UpdateSelection;
  selection: SelectionResponse;
};

export type SelectionAction = UpdateSelectionAction;

export function updateSelectionAction(
  selection: SelectionResponse,
): UpdateSelectionAction {
  return {
    type: SelectionActionTypes.UpdateSelection,
    selection,
  };
}

export function selectionReducer(
  state: SelectionState = defaultSelectionState,
  action: SelectionAction,
): SelectionState {
  switch (action.type) {
    case SelectionActionTypes.UpdateSelection:
      return {
        ...state,
        selection: action.selection,
      };

    default:
      return state;
  }
}
