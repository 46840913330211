/*
 * This module contains everything for the 'localization' section of the Redux store.
 * (State, actions, action factory methods, reducer)
 */

import LanguageCode from '../model/languageCode';

export type LocalizationState = {
  language: LanguageCode | null;
};

export const defaultLocalizationState: LocalizationState = {
  language: null,
};

export enum LocalizationActionTypes {
  UpdateLanguage = 'Localization_UpdateLanguage',
}

export type UpdateLanguageAction = {
  type: typeof LocalizationActionTypes.UpdateLanguage;
  language: LanguageCode;
};

export type LocalizationAction = UpdateLanguageAction;

export function updateLanguageAction(
  language: LanguageCode,
): UpdateLanguageAction {
  return {
    type: LocalizationActionTypes.UpdateLanguage,
    language,
  };
}

export function localizationReducer(
  state: LocalizationState = defaultLocalizationState,
  action: LocalizationAction,
): LocalizationState {
  switch (action.type) {
    case LocalizationActionTypes.UpdateLanguage:
      return {
        ...state,
        language: action.language,
      };

    default:
      return state;
  }
}
