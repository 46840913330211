/* istanbul ignore file */

import { useSelector } from 'react-redux';
import { AuthState } from '../store/authStore';
import { RootState } from '../store/rootStore';
import { SearchResultsState } from '../store/searchResultsStore';
import { SelectionState } from '../store/selectionStore';
import { LocalizationState } from '../store/localizationStore';

export const useAuthState = (): AuthState => {
  const selector = (state: RootState): AuthState => state.auth;
  return useSelector(selector);
};

export const useLocalizationState = (): LocalizationState => {
  const selector = (state: RootState): LocalizationState => state.localization;
  return useSelector(selector);
};

export const useSearchResultsState = (): SearchResultsState => {
  const selector = (state: RootState): SearchResultsState =>
    state.searchResults;
  return useSelector(selector);
};

export const useSelectionState = (): SelectionState => {
  const selector = (state: RootState): SelectionState => state.selection;
  return useSelector(selector);
};
