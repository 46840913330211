import { CardHeader, Avatar } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { FC } from 'react';
import PowerDMSLogo from '../Assets/PowerDMSLogo';
import CloseFilePickerButton from '../Buttons/CloseFilePickerButton/CloseFilePickerButton';

const useStyles = makeStyles({
  avatar: {
    background: 'transparent',
  },
});

const AppHeader: FC = () => {
  const classes = useStyles();

  return (
    <CardHeader
      avatar={
        <Avatar className={classes.avatar}>
          <PowerDMSLogo color="primary" />
        </Avatar>
      }
      title="PowerDMS"
      action={<CloseFilePickerButton />}
      titleTypographyProps={{ align: 'left', variant: 'h1' }}
    />
  );
};

export default AppHeader;
