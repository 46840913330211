import React, { FC } from 'react';
import {
  ResultsStatus,
  ResultSetViewModel,
} from '../../model/resultsViewModel';
import ErrorView from './ErrorView/ErrorView';
import InitialView from './InitialView/InitialView';
import ResultsView from './ResultsView/ResultsView';
import ZeroResultsView from './ZeroResultsView/ZeroResultsView';
import { useSearchResultsState } from '../../hooks/selectors';

type ResultsContainerProps = {
  searchResults: ResultSetViewModel;
};

export const ResultsContainer: FC<{
  searchResults: ResultSetViewModel;
}> = ({ searchResults }: ResultsContainerProps) => {
  if (searchResults.status === ResultsStatus.Error) {
    return <ErrorView />;
  }

  if (searchResults.status === ResultsStatus.Success) {
    if (searchResults.totalAvailableResults === 0) {
      return <ZeroResultsView queryText={searchResults.queryText} />;
    }
    return <ResultsView />;
  }

  return <InitialView />;
};

export const ConnectedResultsContainer: FC = () => {
  const { results } = useSearchResultsState();
  return <ResultsContainer searchResults={results} />;
};
