/* istanbul ignore file */

/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */

const logger = {
  error: (message?: any, ...optionalParams: any[]): void => {
    console.error(message, optionalParams);
  },

  warn: (message?: any, ...optionalParams: any[]): void => {
    console.warn(message, optionalParams);
  },

  info: (message?: any, ...optionalParams: any[]): void => {
    console.log(message, optionalParams);
  },
};

export default logger;
