/*
 * This module contains everything for the 'auth' section of the Redux store.
 * (State, actions, action factory methods, reducer)
 */

import RequestStatus from '../model/requestStatus';

export type TokenPair = {
  accessToken: string;
  idToken: string;
};

export type AuthState = {
  popUpStatus: RequestStatus;
  tokens: TokenPair | null;
  isRefreshing: boolean;
};

export const defaultAuthState: AuthState = {
  popUpStatus: RequestStatus.None,
  tokens: null,
  isRefreshing: false,
};

export enum AuthActionTypes {
  AttemptRefresh = 'Auth_AttemptRefresh',
  PopUpStatusChanged = 'Auth_PopUpStatusChanged',
  UpdateTokens = 'Auth_UpdateTokens',
}

export type AttemptRefreshAction = {
  type: typeof AuthActionTypes.AttemptRefresh;
};

export type AuthPopUpStatusChangedAction = {
  type: typeof AuthActionTypes.PopUpStatusChanged;
  status: RequestStatus;
};

export type UpdateTokensAction = {
  type: typeof AuthActionTypes.UpdateTokens;
  tokens: TokenPair;
};

export type AuthAction =
  | AttemptRefreshAction
  | AuthPopUpStatusChangedAction
  | UpdateTokensAction;

export function attemptRefreshAction(): AttemptRefreshAction {
  return {
    type: AuthActionTypes.AttemptRefresh,
  };
}

export function authPopUpStatusChangedAction(
  status: RequestStatus,
): AuthPopUpStatusChangedAction {
  return {
    type: AuthActionTypes.PopUpStatusChanged,
    status,
  };
}

export function updateTokensAction(tokens: TokenPair): UpdateTokensAction {
  return {
    type: AuthActionTypes.UpdateTokens,
    tokens,
  };
}

export function authReducer(
  state: AuthState = defaultAuthState,
  action: AuthAction,
): AuthState {
  switch (action.type) {
    case AuthActionTypes.AttemptRefresh:
      return {
        ...state,
        isRefreshing: true,
      };

    case AuthActionTypes.PopUpStatusChanged:
      return {
        ...state,
        popUpStatus: action.status,
      };

    case AuthActionTypes.UpdateTokens:
      return {
        ...state,
        tokens: action.tokens,
        isRefreshing: false,
      };

    default:
      return state;
  }
}
