/* istanbul ignore */
import SearchService, { ISearchService } from './searchService';
import { getConfig } from './configService';
import AuthService, { IAuthService } from './authService';
import ApiService, { IApiService } from './apiService';
import { store } from '../store/rootStore';
import { FilePickerConfig } from '../model/configModel';
import { updateTokensAction } from '../store/authStore';
import LocalizationService from './localizationService';
import { updateLanguageAction } from '../store/localizationStore';

export interface ServiceLocator {
  api: IApiService;
  auth: IAuthService;
  search: ISearchService;
}

let instance: ServiceLocator;

function updateStoreBasedOnConfig(config: FilePickerConfig): void {
  const { lang, auth } = config;
  const { idToken, accessToken } = auth;

  if (accessToken && idToken) {
    store.dispatch(
      updateTokensAction({
        accessToken,
        idToken,
      }),
    );
  }

  LocalizationService.loadLanguage(lang).then(() =>
    store.dispatch(updateLanguageAction(lang)),
  );
}

function createServiceLocator(): ServiceLocator {
  const config = getConfig();
  updateStoreBasedOnConfig(config);

  const auth = new AuthService(config.auth);
  const api = new ApiService(auth, store);
  const search = new SearchService(api, config, store);

  return {
    api,
    auth,
    search,
  };
}

/**
 * Initializes dependency graph of services.
 * Should be called only once during app startup.
 */
export function initServiceLocator(): void {
  instance = createServiceLocator();
}

/**
 * Gets a Service Locator object that can be used to
 * get services in the dependency graph.
 * This should only be used in React components, where
 * dependency injection is not possible.
 */
export function getServiceLocator(): ServiceLocator {
  if (!instance) {
    throw Error('ServiceLocator cannot be used before being initialized.');
  }
  return instance;
}
