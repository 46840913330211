import { CardContent } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useAuthState } from '../../hooks/selectors';
import { sendTokensToHost } from '../../services/windowMessageService';

/**
 * This page is what the auth modal will redirect to after authentication is complete.
 * It must send a message back to the host window to launch the File Picker and then
 * close the modal.
 */

const AuthFinalizePage: FC = () => {
  const { tokens } = useAuthState();

  useEffect(() => {
    if (!tokens) {
      throw new Error('No tokens!');
    }

    sendTokensToHost(tokens);
    window.close();
  }, [tokens]); // The dependency array here is critical, unless you want a loop that opens multiple file pickers.

  return (
    <>
      <CardContent>Finalizing authentication...</CardContent>
    </>
  );
};

export default AuthFinalizePage;
