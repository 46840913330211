/* istanbul ignore file */

import { createMuiTheme } from '@material-ui/core/styles';

const customMuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#0277bd',
    },
  },
  typography: {
    h1: {
      fontSize: '2rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h6: {
      fontSize: '1.2rem',
      fontWeight: 'lighter',
    },
  },
});

export default customMuiTheme;
