import qs from 'qs';
import LanguageCode, { toLanguageCodeOrDefault } from '../model/languageCode';
import { FilePickerConfig } from '../model/configModel';

function getQueryParameters(): qs.ParsedQs {
  // Remove leading question mark for qs
  const query = window.location.search?.substring(1);
  return qs.parse(query);
}

export function getUserLanguage(query: qs.ParsedQs): LanguageCode {
  // Get from query parameter, default to English if missing
  let locale = (query.locale as string) || LanguageCode.English;

  // Remove region and normalize to lowercase
  locale = locale.substr(0, 2).toLowerCase();

  // Default to English if invalid
  return toLanguageCodeOrDefault(locale);
}

export function getConfig(): FilePickerConfig {
  const query = getQueryParameters();

  return {
    apiUrl: process.env.REACT_APP_API_URL as string,
    auth: {
      accessToken: query.access_token as string,
      idToken: query.id_token as string,
      refreshUrl: query.refresh_url as string,
    },
    lang: getUserLanguage(query),
  };
}
