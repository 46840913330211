import React, { FC } from 'react';
import { Container, Typography } from '@material-ui/core';

import LocalizationService from '../../../services/localizationService';
import * as Keys from '../../../services/localizationKeys';

import styleHook from '../styles';
import EmptyStateImg from '../../Assets/EmptyStateImg';

const InitialView: FC = (): JSX.Element => {
  const styles = styleHook();

  const searchEmptyStateMessage = LocalizationService.localize(
    Keys.SearchEmptyStateMessage,
    {},
  );

  return (
    <Container
      className={styles.container}
      maxWidth="xl"
      data-testid="default-container"
    >
      <EmptyStateImg role="presentation" />
      <Typography className={styles.text} variant="h5">
        {searchEmptyStateMessage}
      </Typography>
    </Container>
  );
};

export default InitialView;
