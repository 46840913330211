import React, { FC, MouseEvent } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LocalizationService from '../../../services/localizationService';
import * as Keys from '../../../services/localizationKeys';

interface FolderBreadcrumbsProps {
  folderNames: string[];
}

const useStyles = makeStyles({
  nav: {
    maxWidth: '79vw',
    wordWrap: 'break-word',
    cursor: 'default',
  },
  fileText: {
    color: '#424242',
  },
});

const preventEventBubbling = (event: MouseEvent<HTMLElement>): void => {
  event.preventDefault();
  event.stopPropagation();
};

const FolderBreadcrumbs: FC<FolderBreadcrumbsProps> = ({ folderNames }) => {
  const classes = useStyles();

  return (
    <Breadcrumbs
      aria-label={LocalizationService.localize(Keys.BreadcrumbLabel, {})}
      className={classes.nav}
      itemsAfterCollapse={2}
      itemsBeforeCollapse={1}
      maxItems={3}
      onClick={preventEventBubbling}
      component="div"
    >
      {folderNames.map(
        (name, i, arr): JSX.Element => (
          <Typography
            aria-current={arr.length - 1 === i}
            component="span"
            className={classes.fileText}
            key={i.toString()}
          >
            {name}
          </Typography>
        ),
      )}
    </Breadcrumbs>
  );
};
FolderBreadcrumbs.propTypes = {
  folderNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default FolderBreadcrumbs;
