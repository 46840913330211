import { ServiceResponse } from '../model/errorHandlingDto';
import { FilePickerStore } from '../store/rootStore';
import { IAuthService } from './authService';
import { updateTokensAction, attemptRefreshAction } from '../store/authStore';

export interface IApiService {
  tryWithRefresh: <T>(key: string, apiCall: () => Promise<T>) => Promise<T>;
}

export default class ApiService implements IApiService {
  /*
   * API calls use a Circuit Breaker pattern.
   * When a request returns 401, attempt to refresh.
   * Dispatching the AttemptRefreshAction will block
   * further requests from being sent, but will save the
   * most recent query in 'mostRecentRequests'. When the tokens are
   * refreshed, the last query will be executed.
   */

  private readonly mostRecentRequests = new Map<string, unknown>();

  constructor(
    private readonly auth: IAuthService,
    private readonly store: FilePickerStore,
  ) {}

  public static unboxServiceResponse<T>(
    serviceResponse: ServiceResponse<T>,
  ): T {
    if (serviceResponse.error) {
      throw new Error(serviceResponse.error.messages[0]);
    } else {
      return serviceResponse.data as T;
    }
  }

  /**
   * Attempts given API call. If unauthorized, refreshes tokens and tries again.
   * After refreshing, the last API call with the given key will be tried.
   * Any additional requests made while refreshing will throw errors.
   * @param key A key to identify the given operation.
   * All requests with the same key must return the same type.
   * @param apiCall A function that calls the API asynchronously.
   */
  public async tryWithRefresh<T>(
    key: string,
    apiCall: () => Promise<T>,
  ): Promise<T> {
    const { isRefreshing, tokens } = this.store.getState().auth;

    if (!tokens) {
      throw Error('Cannot make API call without access token');
    }

    this.mostRecentRequests.set(key, apiCall);

    if (isRefreshing) {
      throw Error('Cannot search while attempting to refresh token');
    }

    try {
      return await apiCall();
    } catch (e) {
      if (e.statusCode === 401) {
        this.store.dispatch(attemptRefreshAction());
        const tokenResponse = await this.auth.refreshTokens(tokens?.idToken);
        this.store.dispatch(updateTokensAction(tokenResponse));

        const call = this.mostRecentRequests.get(key) as () => Promise<T>;

        // eslint-disable-next-line no-return-await
        return await call();
      }

      throw e;
    }
  }
}
