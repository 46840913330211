/* istanbul ignore file */
import React, { FC } from 'react';
import { ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles({
  li: {
    paddingTop: '1.2em',
  },
  skeletonAvatar: {
    height: 40,
    width: 40,
  },
  skeletonTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  skeletonTitle: {
    height: 25,
    marginBottom: 4,
    marginLeft: 15,
    width: '50vw',
  },
  skeletonSubTitle: {
    height: 15,
    marginLeft: 15,
    width: '30vw',
  },
});

const LoadingResult: FC = (): JSX.Element => {
  const classes = useStyles();
  return (
    <ListItem alignItems="flex-start" className={classes.li}>
      <Skeleton
        animation="wave"
        className={classes.skeletonAvatar}
        variant="circle"
      />
      <div className={classes.skeletonTextContainer}>
        <Skeleton animation="wave" className={classes.skeletonTitle} />
        <Skeleton animation="wave" className={classes.skeletonSubTitle} />
      </div>
    </ListItem>
  );
};

export default LoadingResult;
