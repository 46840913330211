/*  useKeyPress custom React Hook
 - Handles all key press events then removes the event listener from the window once executed
 Accepts:
    a key: the key that the user would press in the situation
    a callback: a function that handles what to do with the key press
*/

import { useEffect } from 'react';

declare global {
  interface Window {
    MSInputMethodContext: unknown;
  }
}

// Record to keep track of key codes for IE
const keyToCode: Record<string, number> = {
  // eslint-disable-next-line
  'Escape': 27,
};

const useKeyPress = (key: string, callback: () => void): void => {
  useEffect(() => {
    const isIE11 = !!window.MSInputMethodContext;
    const handler = (e: KeyboardEvent): void => {
      if (e.key === key) {
        callback();
      }
    };
    const ieHandler = (e: KeyboardEvent): void => {
      if (e.which === keyToCode[key]) {
        callback();
      }
    };

    if (isIE11) {
      window.addEventListener('keypress', ieHandler);
    } else {
      window.addEventListener('keyup', handler);
    }

    return (): void => {
      if (isIE11) {
        window.removeEventListener('keypress', ieHandler);
      } else {
        window.removeEventListener('keyup', handler);
      }
    };
  }, [callback, key]);
};

export default useKeyPress;
