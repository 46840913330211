import {
  SearchResultsDto,
  ObjectStatusDto,
  SearchDocumentDto,
  SearchResultDto,
  FolderBreadcrumbDto,
  BreadcrumbFolderDto,
} from './searchDto';

export enum ResultsStatus {
  Success,
  NoQuery,
  Error,
}

export type ParentFolderViewModel = {
  id: number;
  name: string;
};

export type DocumentViewModel = {
  id: number;
  name: string;
  objectStatus: ObjectStatusDto;
  breadcrumbs: ParentFolderViewModel[];
  draftRevisionId: number | null;
  publishedRevisionId: number | null;
};

export type ResultSetViewModel = {
  queryText: string;
  status: ResultsStatus;
  results: DocumentViewModel[];
  totalAvailableResults: number;
};

export const mapDtoParentFolderToViewModel = (
  source: BreadcrumbFolderDto,
): ParentFolderViewModel => {
  // Explicitly map properties to avoid unused properties in the DTO from getting passed through
  return {
    id: source.id,
    name: source.name,
  };
};

export const mapDtoBreadcrumbsToViewModel = (
  breadcrumbs: FolderBreadcrumbDto[],
): ParentFolderViewModel[] => {
  // Will have 0 elements if at root
  if (breadcrumbs.length === 0) {
    return [];
  }

  // If not at root, will have exactly 1 trail
  return breadcrumbs[0].trail.map(mapDtoParentFolderToViewModel);
};

export const mapDtoResultToViewModel = (
  result: SearchResultDto,
): DocumentViewModel => {
  const docDto = result.result as SearchDocumentDto;
  return {
    id: docDto.id,
    name: docDto.name,
    objectStatus: docDto.objectStatus,
    breadcrumbs: mapDtoBreadcrumbsToViewModel(docDto.breadcrumbs),
    draftRevisionId: docDto.draftRevisionId,
    publishedRevisionId: docDto.publishedRevisionId,
  };
};

export const getSuccessResultSet = (
  queryText: string,
  response: SearchResultsDto,
): ResultSetViewModel => {
  return {
    queryText,
    status: ResultsStatus.Success,
    results: response.results.map(mapDtoResultToViewModel),
    totalAvailableResults: response.totalNumberOfResults,
  };
};

export const getNoQueryResultSet = (): ResultSetViewModel => {
  return {
    queryText: '',
    status: ResultsStatus.NoQuery,
    results: [],
    totalAvailableResults: 0,
  };
};

export const getErrorResultSet = (queryText: string): ResultSetViewModel => {
  return {
    queryText,
    status: ResultsStatus.Error,
    results: [],
    totalAvailableResults: 0,
  };
};
