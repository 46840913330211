/* istanbul ignore file */

// ENUMS

export enum ObjectStatusDto {
  Draft = 'draft',
  Published = 'published',
  Archived = 'archived',
}

export enum ObjectTypeDto {
  Document = 'document',
  Folder = 'folder',
}

export enum SelectFieldDto {
  Breadcrumbs = 'breadcrumbs',
}

// REQUEST OBJECTS

export type SearchQueryDto = {
  objectTypes: ObjectTypeDto[];
  limit: number;
  offset: number;
  select: SelectFieldDto[];
  query: string;
  returnDraft: boolean;
  returnPublished: boolean;
  returnArchived: boolean;
};

// RESPONSE OBJECTS

// Page of search results
export type SearchResultsDto = {
  totalNumberOfResults: number;
  results: SearchResultDto[];
};

// Wrapper object for single search result
export type SearchResultDto = {
  result: SearchCcmpObjectDto;
};

// Base type for objects returned as search results
export type SearchCcmpObjectDto = CcmpObjectDto & {
  description: string;
};

// Base type for DMS objects
export type CcmpObjectDto = {
  id: number;
  siteId: number;
  name: string;
  objectStatus: ObjectStatusDto;
  objectType: ObjectTypeDto;
};

// Document returned as search result
export type SearchDocumentDto = SearchCcmpObjectDto & {
  breadcrumbs: FolderBreadcrumbDto[];
  draftRevisionId: number | null;
  publishedRevisionId: number | null;
};

// Hierachy of nested folders an object belongs in
export type FolderBreadcrumbDto = {
  trail: BreadcrumbFolderDto[];
};

// Folder in the context of a breadcrumb on another object
export type BreadcrumbFolderDto = CcmpObjectDto;
