/* istanbul ignore file */

enum LanguageCode {
  English = 'en',
  Spanish = 'es',
}
export default LanguageCode;

export function toLanguageCodeOrDefault(lang: string): LanguageCode {
  return Object.values(LanguageCode).includes(lang as LanguageCode)
    ? (lang as LanguageCode)
    : LanguageCode.English;
}
