/* istanbul ignore file */
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const styleHook = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('sm')]: {
        height: '40vh',
      },
      [theme.breakpoints.down('md')]: {
        height: 'auto',
      },
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      height: '52vh',
      justifyContent: 'center',
      maxWidth: '100vw',
      minHeight: '52vh',
    },
    text: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1em',
      },
      textAlign: 'center',
    },
    textTitle: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5em',
      },
      textAlign: 'center',
    },
  }),
);

export default styleHook;
