/* istanbul ignore file */

import 'react-app-polyfill/ie11'; // Required for react-app-polyfill
import 'react-app-polyfill/stable';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import 'fontsource-roboto';
import './index.css';
import { initServiceLocator } from './services/serviceLocator';

initServiceLocator();

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);
