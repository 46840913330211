/*
 * This module contains everything for the 'searchResults' section of the Redux store.
 * (State, actions, action factory methods, reducer)
 */

import {
  ResultSetViewModel,
  getNoQueryResultSet,
} from '../model/resultsViewModel';

export type SearchResultsState = {
  results: ResultSetViewModel;
};

export const defaultSearchResultsState: SearchResultsState = {
  results: getNoQueryResultSet(),
};

export enum SearchResultsActionTypes {
  UpdateResults = 'SearchResults_Update',
}

export type UpdateResultsAction = {
  type: typeof SearchResultsActionTypes.UpdateResults;
  results: ResultSetViewModel;
};

export type SearchResultsAction = UpdateResultsAction;

export function updateResultsAction(
  results: ResultSetViewModel,
): UpdateResultsAction {
  return {
    type: SearchResultsActionTypes.UpdateResults,
    results,
  };
}

export function searchResultsReducer(
  state: SearchResultsState = defaultSearchResultsState,
  action: SearchResultsAction,
): SearchResultsState {
  switch (action.type) {
    case SearchResultsActionTypes.UpdateResults:
      return {
        ...state,
        results: action.results,
      };

    default:
      return state;
  }
}
