/* eslint-disable react/prop-types */
import React, { FC } from 'react';
import {
  Avatar,
  Checkbox,
  Divider,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import DocumentIcon from '../Assets/DocumentIcon';
import { addSelection, removeSelection } from '../../model/clientResponseModel';
import { DocumentViewModel } from '../../model/resultsViewModel';
import FolderBreadcrumbs from './FolderBreadcrumbs/FolderBreadcrumbs';
import { useSelectionState } from '../../hooks/selectors';
import { updateSelectionAction } from '../../store/selectionStore';

interface ResultProps {
  document: DocumentViewModel;
}

const useStyles = makeStyles({
  avatar: {
    background: 'transparent',
  },
  li: {
    background: 'transparent',
    '&:focus': {
      background: '#E1F4FD',
    },
    '&:active': {
      background: '#E1F4FD',
    },
  },
  primary: {
    color: '#212121',
    fontWeight: 'normal',
    marginRight: 5,
  },
});

const Result: FC<ResultProps> = ({ document }) => {
  const classes = useStyles();
  const { selection } = useSelectionState();
  const dispatch = useDispatch();

  const onCheckboxChanged = (checked: boolean): void => {
    const newSelection = checked
      ? addSelection(selection, document)
      : removeSelection(selection, document);

    dispatch(updateSelectionAction(newSelection));
  };

  const isChecked = selection.selectedFiles.some(
    (selected) => selected.documentId === document.id,
  );

  const folderNames = document.breadcrumbs.map((folder) => folder.name);
  const labelId = document.id.toString();
  const ariaId = `${labelId}-aria`;

  return (
    <>
      <InputLabel htmlFor={ariaId}>
        <ListItem
          alignItems="flex-start"
          button
          className={classes.li}
          ContainerComponent="div"
          selected={isChecked}
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar} role="presentation">
              <DocumentIcon color="inherit" fontSize="large" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            disableTypography
            id={labelId}
            primary={
              <Typography className={classes.primary} variant="h6">
                {document.name}
              </Typography>
            }
            secondary={<FolderBreadcrumbs folderNames={folderNames} />}
          />
          <ListItemSecondaryAction>
            <Checkbox
              aria-labelledby={labelId}
              color="primary"
              edge="end"
              id={ariaId}
              checked={isChecked}
              onChange={(_, checked): void => onCheckboxChanged(checked)}
              data-automation-id="file-picker-result-checkbox"
            />
          </ListItemSecondaryAction>
        </ListItem>
      </InputLabel>
      <Divider />
    </>
  );
};

export default Result;
