import React, { FC } from 'react';
import { Container, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import LocalizationService from '../../../services/localizationService';
import * as Keys from '../../../services/localizationKeys';

import EmptyStateImg from '../../Assets/EmptyStateImg';
import styleHook from '../styles';

type ZeroResultsViewProps = {
  queryText: string;
};

const ZeroResultsView: FC<ZeroResultsViewProps> = ({
  queryText,
}): JSX.Element => {
  const styles = styleHook();

  const messageTitle = LocalizationService.localize(
    Keys.NoResultsMessageTitle,
    {
      queryText,
    },
  );
  const messageSubTitle = LocalizationService.localize(
    Keys.NoResultsMessageSubTitle,
    {
      queryText,
    },
  );

  return (
    <Container className={styles.container}>
      <EmptyStateImg role="presentation" />
      <Typography className={styles.textTitle} variant="h5">
        {messageTitle}
      </Typography>
      <Typography className={styles.text} variant="subtitle1">
        {messageSubTitle}
      </Typography>
    </Container>
  );
};

ZeroResultsView.propTypes = {
  queryText: PropTypes.string.isRequired,
};

export default ZeroResultsView;
