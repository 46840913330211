/* istanbul ignore file */
import React, { FC } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import customMuiTheme from '../../styles/custom-theming';
import './App.css';
import { store } from '../../store/rootStore';
import PageContainer from '../PageContainer/PageContainer';

const App: FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={customMuiTheme}>
        <PageContainer />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
