/* istanbul ignore file */

export enum FilePickerEventType {
  Close = 'DMS_FILEPICKER_CLOSE',
  FileSelected = 'DMS_FILEPICKER_FILESELECTED',
  SendTokensToHost = 'DMS_FILEPICKER_SENDTOKENSTOHOST',
}

export interface FilePickerEvent<T> {
  type: FilePickerEventType;
  data?: T;
}
