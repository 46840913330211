import React, { FC } from 'react';
import { useAuthState } from '../../hooks/selectors';

const RedirectIfUnauthenticated: FC = () => {
  const { tokens } = useAuthState();

  // If we have tokens, show the file picker.
  if (tokens) {
    return <></>;
  }

  // TODO: We may want this to do something more graceful
  throw Error('Unauthenticated.');
};

export default RedirectIfUnauthenticated;
