import {
  SearchQueryDto,
  SearchResultsDto,
  ObjectTypeDto,
  SelectFieldDto,
} from '../model/searchDto';
import { ServiceResponse } from '../model/errorHandlingDto';
import * as HttpService from './httpService';
import logger from './logger';
import {
  ResultSetViewModel,
  getNoQueryResultSet,
  getSuccessResultSet,
  getErrorResultSet,
  ResultsStatus,
} from '../model/resultsViewModel';
import { FilePickerConfig } from '../model/configModel';
import ApiService, { IApiService } from './apiService';
import { FilePickerStore } from '../store/rootStore';

const pageSize = 50;

export interface ISearchService {
  getFirstPageOfResults: (queryText: string) => Promise<ResultSetViewModel>;
  getNextPageOfResults: (
    previousResults: ResultSetViewModel,
  ) => Promise<ResultSetViewModel>;
}

export default class SearchService implements ISearchService {
  constructor(
    private readonly api: IApiService,
    private readonly config: FilePickerConfig,
    private readonly store: FilePickerStore,
  ) {}

  public static createRequest(
    queryText: string,
    offset: number,
  ): SearchQueryDto {
    return {
      query: queryText,
      limit: pageSize,
      offset,
      objectTypes: [ObjectTypeDto.Document],
      select: [SelectFieldDto.Breadcrumbs],
      returnPublished: true,
      returnDraft: true,
      returnArchived: true,
    };
  }

  /* istanbul ignore next */
  public async sendRequest(
    query: SearchQueryDto,
  ): Promise<ServiceResponse<SearchResultsDto>> {
    const { apiUrl } = this.config;
    const { auth } = this.store.getState();

    if (!auth.tokens) {
      throw Error('Cannot send request without tokens.');
    }

    const request: HttpService.HttpRequest<SearchQueryDto> = {
      url: `${apiUrl}/v1/search`,
      acceptHeader: 'application/json',
      authorizationHeader: `Bearer ${auth.tokens.accessToken}`,
      contentTypeHeader: 'application/json',
      data: query,
      withCredentials: true,
    };

    const response = await HttpService.post<
      SearchQueryDto,
      ServiceResponse<SearchResultsDto>
    >(request);

    return response;
  }

  /* istanbul ignore next */
  public async search(
    queryText: string,
    offset: number,
  ): Promise<SearchResultsDto> {
    return this.api.tryWithRefresh('search', async () => {
      const query = SearchService.createRequest(queryText, offset);
      const response = await this.sendRequest(query);
      return ApiService.unboxServiceResponse(response);
    });
  }

  public async getFirstPageOfResults(
    queryText: string,
  ): Promise<ResultSetViewModel> {
    if (queryText.length === 0) {
      logger.info('No query');
      return getNoQueryResultSet();
    }

    try {
      const response = await this.search(queryText, 0);
      const results = getSuccessResultSet(queryText, response);
      logger.info(results);
      return results;
    } catch (error) {
      logger.error(error);
      return getErrorResultSet(queryText);
    }
  }

  public async getNextPageOfResults(
    previousResults: ResultSetViewModel,
  ): Promise<ResultSetViewModel> {
    const { queryText } = previousResults;

    if (previousResults.status === ResultsStatus.NoQuery) {
      logger.warn('Cannot get additional page of results when query is blank.');
      return previousResults;
    }

    if (
      previousResults.results.length >= previousResults.totalAvailableResults
    ) {
      logger.info('All results have been fetched already.');
      return previousResults;
    }

    const offset = previousResults.results.length;

    try {
      const response = await this.search(queryText, offset);
      const newResults = getSuccessResultSet(queryText, response);
      logger.info(newResults);

      const totalResults = {
        ...previousResults,
        results: [...previousResults.results, ...newResults.results],
      };
      return totalResults;
    } catch (error) {
      logger.error(error);
      return getErrorResultSet(queryText);
    }
  }
}
