/* istanbul ignore file */

import {
  FilePickerEvent,
  FilePickerEventType,
} from '../model/windowMessageModel';
import { SelectionResponse } from '../model/clientResponseModel';
import { TokenPair } from '../store/authStore';

/**
 * Sends message to initializer module in partner site window to close the IFrame.
 */
export const closeIFrame = (): void => {
  const e: FilePickerEvent<void> = {
    type: FilePickerEventType.Close,
  };

  /* istanbul ignore next */
  window.parent.postMessage(e, '*');
};

/**
 * Sends message to initializer module in partner site window containing data
 * about the selected document(s).
 */
export const submitSelection = (response: SelectionResponse): void => {
  const e: FilePickerEvent<SelectionResponse> = {
    type: FilePickerEventType.FileSelected,
    data: response,
  };

  /* istanbul ignore next */
  window.parent.postMessage(e, '*');
};

/**
 * Sends message from the auth pop-up window back to the host window to give it the tokens.
 */
export const sendTokensToHost = (tokens: TokenPair): void => {
  const e: FilePickerEvent<TokenPair> = {
    type: FilePickerEventType.SendTokensToHost,
    data: tokens,
  };

  /* istanbul ignore next */
  window.opener.postMessage(e, '*');
};
