/* istanbul ignore file */

import React, { FC, ChangeEvent } from 'react';
import { InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import LocalizationService from '../../../services/localizationService';
import * as Keys from '../../../services/localizationKeys';
import SearchIcon from '../../Assets/SearchIcon';
import { getEmptySelection } from '../../../model/clientResponseModel';
import { getServiceLocator } from '../../../services/serviceLocator';
import { updateSelectionAction } from '../../../store/selectionStore';
import { updateResultsAction } from '../../../store/searchResultsStore';

const SearchInput: FC = () => {
  const dispatch = useDispatch();

  const trySearch = async (queryText: string): Promise<void> => {
    const { search } = getServiceLocator();
    const results = await search.getFirstPageOfResults(queryText);
    dispatch(updateResultsAction(results));
  };

  const debouncedSearch = debounce(trySearch, 300);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    debouncedSearch(event.target.value);
  };

  const handleFocus = (): void => {
    const selection = getEmptySelection();
    dispatch(updateSelectionAction(selection));
  };

  const ariaLabel = LocalizationService.localize(Keys.SearchAria, {});
  const placeholderText = LocalizationService.localize(
    Keys.SearchPlaceholder,
    {},
  );

  return (
    <>
      <InputLabel htmlFor="search-input" />
      <OutlinedInput
        autoFocus
        id="search-input"
        inputProps={{
          'aria-label': ariaLabel,
          'data-automation-id': 'file-picker-search-textbox',
        }}
        placeholder={placeholderText}
        onChange={handleChange}
        onFocus={handleFocus}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </>
  );
};

export default SearchInput;
