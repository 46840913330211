import { ObjectStatusDto } from './searchDto';
import { DocumentViewModel, ParentFolderViewModel } from './resultsViewModel';
import { getConfig } from '../services/configService';

export type ResponseParentFolder = {
  id: number;
  name: string;
};

export type ResponseFileInfo = {
  documentId: number;
  documentName: string;
  breadcrumbs: ResponseParentFolder[];
  revisionId: number;
  revisionStatus: ObjectStatusDto;
  contentUrl: string;
};

export type SelectionResponse = {
  selectedFiles: ResponseFileInfo[];
};

export const mapParentFolderViewModelToResponse = (
  viewModel: ParentFolderViewModel,
): ResponseParentFolder => {
  // Explicitly map properties to avoid returning extra data to client
  return {
    id: viewModel.id,
    name: viewModel.name,
  };
};

export const mapDocumentViewModelToResponse = (
  viewModel: DocumentViewModel,
): ResponseFileInfo => {
  const documentId = viewModel.id;
  const revisionId = (viewModel.publishedRevisionId ??
    viewModel.draftRevisionId) as number;

  const { apiUrl } = getConfig();

  return {
    documentId,
    documentName: viewModel.name,
    breadcrumbs: viewModel.breadcrumbs.map(mapParentFolderViewModelToResponse),
    revisionId,
    revisionStatus: viewModel.objectStatus,
    contentUrl: `${apiUrl}/v1/documents/${documentId}/revisions/${revisionId}/content`,
  };
};

export const getEmptySelection = (): SelectionResponse => {
  return {
    selectedFiles: [],
  };
};

export const getSingleSelection = (
  document: DocumentViewModel,
): SelectionResponse => {
  const file = mapDocumentViewModelToResponse(document);
  return {
    selectedFiles: [file],
  };
};

// This can be used later to support multi-select
export const addSelection = (
  selection: SelectionResponse,
  document: DocumentViewModel,
): SelectionResponse => {
  const file = mapDocumentViewModelToResponse(document);
  return {
    selectedFiles: selection.selectedFiles.concat([file]),
  };
};

// This can be used later to support multi-select
export const removeSelection = (
  selection: SelectionResponse,
  document: DocumentViewModel,
): SelectionResponse => {
  return {
    selectedFiles: selection.selectedFiles.filter(
      (x) => x.documentId !== document.id,
    ),
  };
};
