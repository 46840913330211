import React, { FC, ReactElement } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LocalizationService from '../../../services/localizationService';
import * as Keys from '../../../services/localizationKeys';
import LoadingResult from '../LoadingResult/LoadingResult';
import Result from '../../Result/Result';
import styleHook from '../styles';
import { getServiceLocator } from '../../../services/serviceLocator';
import { useSearchResultsState } from '../../../hooks/selectors';
import { updateResultsAction } from '../../../store/searchResultsStore';

const useStyles = makeStyles({
  virtualizedList: {
    maxHeight: '52vh',
    width: '100%',
  },
});

const ResultsView: FC = (): JSX.Element => {
  const classes = useStyles();
  const styles = styleHook();
  const { results } = useSearchResultsState();
  const dispatch = useDispatch();

  const items = results.results;
  const hasMoreResults = items.length < results.totalAvailableResults;

  const loadMore = async (): Promise<void> => {
    const { search } = getServiceLocator();
    const updatedResults = await search.getNextPageOfResults(results);
    dispatch(updateResultsAction(updatedResults));
  };

  const getItem = (index: number): ReactElement => {
    const doc = items[index];

    return <Result key={index.toString()} document={doc} />;
  };

  const getFooter = (): ReactElement => {
    if (hasMoreResults) {
      return <LoadingResult />;
    }
    return <div />;
  };

  return (
    <Container className={styles.container}>
      <Virtuoso
        aria-label={LocalizationService.localize(
          Keys.DocumentListAriaDescription,
          {},
        )}
        className={classes.virtualizedList}
        overscan={100}
        totalCount={items.length}
        item={getItem}
        endReached={loadMore}
        footer={getFooter}
      />
    </Container>
  );
};

export default ResultsView;
