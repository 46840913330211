/*
 * This module combines all the store sections into the main "root" Redux store
 */

import { combineReducers, createStore } from 'redux';
import {
  AuthState,
  AuthActionTypes,
  AuthAction,
  defaultAuthState,
  authReducer,
} from './authStore';
import {
  SelectionState,
  SelectionActionTypes,
  SelectionAction,
  defaultSelectionState,
  selectionReducer,
} from './selectionStore';
import {
  SearchResultsState,
  defaultSearchResultsState,
  searchResultsReducer,
  SearchResultsActionTypes,
  SearchResultsAction,
} from './searchResultsStore';
import {
  LocalizationState,
  defaultLocalizationState,
  LocalizationAction,
  localizationReducer,
} from './localizationStore';

export type RootState = {
  auth: AuthState;
  localization: LocalizationState;
  searchResults: SearchResultsState;
  selection: SelectionState;
};

export type RootActionTypes =
  | AuthActionTypes
  | LocalizationState
  | SearchResultsActionTypes
  | SelectionActionTypes;

export type RootAction =
  | AuthAction
  | LocalizationAction
  | SearchResultsAction
  | SelectionAction;

export const defaultRootState: RootState = {
  auth: defaultAuthState,
  localization: defaultLocalizationState,
  searchResults: defaultSearchResultsState,
  selection: defaultSelectionState,
};

export const rootReducer = combineReducers({
  auth: authReducer,
  localization: localizationReducer,
  searchResults: searchResultsReducer,
  selection: selectionReducer,
});

export const store = createStore(rootReducer, defaultRootState);

export type FilePickerStore = typeof store;
