import React, { FC } from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import LocalizationService from '../../../services/localizationService';
import * as Keys from '../../../services/localizationKeys';

import useKeyPress from '../../../hooks/useKeyPress';
import { closeIFrame } from '../../../services/windowMessageService';

const CloseFilePickerButton: FC = () => {
  useKeyPress('Escape', closeIFrame);

  return (
    <IconButton
      data-testid="close-btn"
      data-automation-id="file-picker-close-btn"
      onClick={closeIFrame}
      aria-label={LocalizationService.localize(Keys.CloseButtonLabel, {})}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default CloseFilePickerButton;
