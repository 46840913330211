/* istanbul ignore file */

import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { ReactComponent as Logo } from '../../assets/powerdms-logo.svg';

export default function PowerDMSLogo(props: SvgIconProps): ReactElement {
  const { titleAccess, color } = props;
  return (
    <SvgIcon
      titleAccess={titleAccess}
      color={color}
      component={Logo}
      viewBox="0 0 340 360"
    />
  );
}
