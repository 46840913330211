/* istanbul ignore file */

import { sprintf } from 'sprintf-js';
import * as Keys from './localizationKeys';
import LanguageCode, { toLanguageCodeOrDefault } from '../model/languageCode';
import logger from './logger';

class LocalizationService {
  private static isLoaded = false;

  private static localStrings: LocalStrings = {};

  public static loadLanguage(language: LanguageCode): Promise<void> {
    const lang = toLanguageCodeOrDefault(language);

    return this.getTranslations(lang)
      .then((translations) => {
        Object.keys(translations).forEach((key) => {
          LocalizationService.localStrings[key] = translations[key];
        });
        LocalizationService.isLoaded = true;
      })
      .catch((err) => {
        logger.error('Failed to get local strings.', err);
      });
  }

  private static getTranslations(lang: LanguageCode): Promise<LocalStrings> {
    return fetch(`locales/translations.${lang}.json`)
      .then((response) => response.json())
      .then((obj) => obj as LocalStrings);
  }

  public static localize = <T extends TTranslation>(
    translation: T,
    args: InstanceType<T>,
  ): string => {
    if (!LocalizationService.isLoaded) {
      return '';
    }

    if (translation && args && Object.keys(args).length > 0) {
      return sprintf(LocalizationService.localStrings[translation.key], args);
    }
    if (translation) {
      return LocalizationService.localStrings[translation.key];
    }
    return '';
  };

  public static labelize = <T extends TTranslation>(
    translation: T,
    args: InstanceType<T>,
  ): string => {
    const phrase = LocalizationService.localize(translation, args);

    return LocalizationService.localize(Keys.CommonTerms_Label, {
      label: phrase,
    });
  };

  public static parenthesize = <T extends TTranslation>(
    translation: T,
    args: InstanceType<T>,
  ): string => {
    const phrase = LocalizationService.localize(translation, args);

    return LocalizationService.localize(Keys.CommonTerms_Parentheses, {
      text: phrase,
    });
  };

  public localize: <T extends TTranslation>(
    translation: T,
    args: InstanceType<T>,
  ) => string;

  public labelize: <T extends TTranslation>(
    translation: T,
    args: InstanceType<T>,
  ) => string;

  public parenthesize: <T extends TTranslation>(
    translation: T,
    args: InstanceType<T>,
  ) => string;

  constructor() {
    this.localize = LocalizationService.localize;
    this.labelize = LocalizationService.labelize;
    this.parenthesize = LocalizationService.parenthesize;
  }
}
export default LocalizationService;
